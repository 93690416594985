import React, { Component, Fragment } from "react"
import { withRouter } from "react-router"
import { sizes } from "../Theme"

export class Above extends React.Component {
    state = {
        visible: false
    }

    componentDidMount() {
        this.checkCondition()
        window.addEventListener("resize", this.checkCondition)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkCondition)
    }

    checkCondition = () => {
        const size = Number.isInteger(this.props.size)
            ? this.props.size
            : sizes[this.props.size]
        if (size < window.innerWidth) {
            this.setState({ visible: true })
        } else {
            this.setState({ visible: false })
        }
    }

    render() {
        return this.state.visible ? (
            <Fragment>{this.props.children}</Fragment>
        ) : null
    }
}

export class Below extends React.Component {
    state = {
        visible: false
    }

    componentDidMount() {
        this.checkCondition()
        window.addEventListener("resize", this.checkCondition)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkCondition)
    }

    checkCondition = () => {
        const size = Number.isInteger(this.props.size)
            ? this.props.size
            : sizes[this.props.size]

        if (size >= window.innerWidth) {
            this.setState({ visible: true })
        } else {
            this.setState({ visible: false })
        }
    }

    render() {
        return this.state.visible ? (
            <Fragment>{this.props.children}</Fragment>
        ) : null
    }
}

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop)
