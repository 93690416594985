import React, { Component } from "react"
import styled from "@emotion/styled"
import gql from "graphql-tag"
import { Query } from "react-apollo"
import { withRouter } from "react-router"

const FooterWrapper = styled("footer")`
    text-align: left;
    background: #54575a;
    color: #fff;
    font-size: 1.2rem;
    position: relative;
    z-index: 2;
`

const BusinessInfo = styled("div")`
    padding: 6rem;
    line-height: 1.5em;
    display: flex;
    justify-items: space-between;
    align-items: flex-end;
    font-size: 1rem;
    a {
        color: white;
    }
    h2 {
        margin-bottom: 1em;
        font-family: ${props => props.theme.fonts.body};
        font-weight: 400;
        font-size: 1.2rem;
    }
    ${props => props.theme.bp.below.md} {
        padding: 3rem;
    }
`
/*
const FooterLogos = props => {
	return (
		<FooterLogoWrapper>
			<MaxWidth>
				<LogoFlexWrap>
					{logos.map((logo, index) => {
						return (
							<ImgHolder type={logo.split(".").pop()} key={index}>
								<img alt="logo" src={logo} />
							</ImgHolder>
						)
					})}
				</LogoFlexWrap>
			</MaxWidth>
		</FooterLogoWrapper>
	)
}
*/

const FooterContent = props => {
    return (
        <FooterWrapper>
            <BusinessInfo>
                <div>
                    <h2>{props.site.name}</h2>
                    <span>{props.site.address}</span>,{" "}
                    <span>{props.site.zipcode} </span>
                    <span>{props.site.city}</span>
                    <br />
                    <span>{props.site.phone}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    <a href={`mailto:${props.site.email}`}>
                        {props.site.email}
                    </a>
                    <br />
                    <br />
                    <div
                        dangerouslySetInnerHTML={{ __html: props.site.info }}
                    />
                </div>
            </BusinessInfo>
        </FooterWrapper>
    )
}

class Footer extends Component {
    render() {
        const lang = this.props.match.params.lang || "se"
        return (
            <Query query={query} variables={{ lang }}>
                {({ loading, err, data }) => {
                    if (loading) return <div>loading</div>
                    return <FooterContent {...data} />
                }}
            </Query>
        )
    }
}
const query = gql`
    query Site($lang: String) {
        site(lang: $lang) {
            name
            address
            city
            phone
            email
            zipcode
            info
        }
    }
`
export default withRouter(Footer)
