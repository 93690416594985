import React, { Component } from "react"
import { Link } from "react-router-dom"
import styled from "@emotion/styled"
import { Menu } from "../Menu"
import logo from "../img/logo.png"
import { withRouter } from "react-router"

const HeaderWrapper = styled("header")`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    ${props => props.theme.bp.above.md} {
        height: 120px;
    }
    display: flex;
    align-items: center;

    &.page,
    &.is-scrolled {
        background: #fff;
    }
    ${props => props.theme.bp.below.md} {
        background: #fff;
    }
    .logoWrapper {
        width: 250px;
        height: auto;
        background: transparent;
        ${props => props.theme.bp.above.md} {
            padding: 3rem;
        }
        ${props => props.theme.bp.below.md} {
            width: 120px;
            padding: 0.5rem;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
`

const HeaderContent = props => {
    return (
        <HeaderWrapper
            className={
                props.isScrolled || props.location.pathname !== props.match.url
                    ? " is-scrolled"
                    : ""
            }>
            <div className="logoWrapper">
                <Link to="/">
                    <img src={logo} alt="" />
                </Link>
            </div>
            <Menu {...props} />
        </HeaderWrapper>
    )
}

class Header extends Component {
    state = {
        open: false,
        search: false,
        isScrolled: false
    }
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll)
    }
    handleScroll = () => {
        const scrollDistance = window.innerHeight - 120
        let scrollTop = window.scrollY
        if (scrollTop > scrollDistance && !this.state.isScrolled) {
            this.setState({
                isScrolled: true
            })
        } else if (scrollTop < scrollDistance && this.state.isScrolled) {
            this.setState({
                isScrolled: false
            })
        }
    }

    toggleMenu = () => {
        this.setState({ open: !this.state.open })
    }

    toggleSearch = () => {
        this.setState({ search: !this.state.search })
    }
    render() {
        const RoutesHeadercontent = withRouter(HeaderContent)
        return (
            <RoutesHeadercontent
                {...this.state}
                toggleMenu={this.toggleMenu}
                toggleSearch={this.toggleSearch}
            />
        )
    }
}

export default Header
