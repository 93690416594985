import React from "react";
import { Helmet } from "react-helmet";
import gql from "graphql-tag";
import styled from "@emotion/styled";
import { Query, graphql } from "react-apollo";
import { LoadingPage } from "../UI";
import { MaxWidth } from "../UI/Containers";
import PageHeader from "./PageHeader";
import Contact from "./Contact";
import { StyledBox, CTA } from "./StartPage";

const BoxWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const InfoBox = styled("div")`
  width: 25%;
  background: #bbbcbe;
  color: #fff;
  padding: 5rem 3rem 3rem;
  text-align: center;
  ${(props) => props.theme.bp.below.md} {
    width: 100%;
    padding: 3rem 2rem 0;
    &:last-of-type {
      padding-bottom: 3rem;
    }
  }
  h2 {
    font-size: 1.6rem;
    line-height: 1.2em;
    margin-bottom: 0.2em;
  }
  p {
    font-size: 15px;
    line-height: 1.5em;
  }
  a {
    color: #4d9cce;
  }
  .content {
    margin: 0 auto;
    width: 220px;
    max-width: 100%;
  }
`;

const Padder = styled("div")`
  height: 120px;
  width: 100%;
  display: block;
  ${(props) => props.theme.bp.below.md} {
    height: 85px;
  }
`;

const PageContent = (props) => {
  const page = props.page;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{page.title}</title>
      </Helmet>
      {page.image.url || page.template !== "CONTACT" ? (
        <PageHeader template={page.template} page={true} pageData={page} />
      ) : (
        <Padder />
      )}
      {(page.template === "CONTACT" && <Contact page={page} />) || (
        <React.Fragment>
          {page.content && (
            <PageSection>
              <MaxWidth
                dangerouslySetInnerHTML={{
                  __html: page.content,
                }}
              />
            </PageSection>
          )}
          <BoxWrapper>
            {page.boxSet.map((v, i) => {
              let Component = StyledBox;
              if (v.template && v.template === "INFO") {
                Component = InfoBox;
              }
              return (
                <Component
                  img={v.image.url ? v.image : null}
                  page={true}
                  key={`box-${i}`}
                >
                  {v.template === "INFO" && <h2>{v.title}</h2>}
                  <div className="content">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: v.content,
                      }}
                    />
                  </div>
                  {v.image.url && (
                    <div className="image">
                      <img
                        alt=""
                        src={"//skandinaviskgradteknik.se" + v.image.url}
                      />
                    </div>
                  )}
                </Component>
              );
            })}
          </BoxWrapper>
          <CTA />
        </React.Fragment>
      )}
    </div>
  );
};

class Page extends React.Component {
  render() {
    const slug = this.props.match.params.slug;
    const lang = this.props.match.params.lang;

    return (
      <Query query={query} variables={{ slug, lang }}>
        {({ loading, err, data }) => {
          if (loading) return <LoadingPage />;
          return <PageContent {...data} />;
        }}
      </Query>
    );
  }
}
const query = gql`
  query Page($slug: String, $lang: String) {
    page(slug: $slug, lang: $lang) {
      headTitle
      excerpt
      headLink
      headLinkText
      title
      content
      template
      image {
        url
      }
      boxSet {
        title
        content
        template
        image(version: "large") {
          width
          height
          url
        }
      }
    }
  }
`;

export const PageSection = styled("section")`
	line-height: 1.5em;
	text-align:center;
    &:nth-child(even) {
        background: ${(props) => props.theme.colors.accent};
    }
    padding: ${(props) => props.theme.spacing.default};
    ${(props) => props.theme.bp.below.sm} {
        padding: ${(props) => props.theme.spacing.small};
    }
    > div {
		width:650px;
		max-width:100%;
        ${(props) => props.theme.bp.below.sm} {
            padding: 0px;

    }
`;

export default graphql(query)(Page);
