import React from "react"
import styled from "@emotion/styled"
import gql from "graphql-tag"
import { Query } from "react-apollo"
import { withRouter } from "react-router"

const TestimonialsWrapper = styled("aside")`
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 80px 0;
    ${props => props.theme.bp.below.sm} {
        flex-wrap: wrap;
        padding: 20px 0;
    }
    div {
        width: 100%;
        max-width: 50%;
        padding: 40px;
        text-align: center;
        ${props => props.theme.bp.below.sm} {
            max-width: 100%;
            padding: 30px;
        }
    }
    blockquote p {
        max-width: 720px;
        margin: auto auto .5em auto;
        font-family: ${props => props.theme.fonts.headings};
        font-size: 1.8rem;
        line-height: 1.2em;
        margin-bottom: 0.5em;
        &:before,
        &:after {
            content: "”";
        }
        ${props => props.theme.bp.below.sm} {
            font-size: 1.4rem;
        }
    }
    span {
        text-transform: uppercase;
        font-weight: 100;
    }
`

const Testimonials = props => {
    const lang = props.match.params.lang || "se"
    return (
        <Query query={query} variables={{ lang }}>
            {({ loading, err, data }) => {
                if (loading) return null
                return (
                    <TestimonialsWrapper>
                        {console.log(data)}
                        {data.allReferences.edges.map((v, i) => {
                            return (
                                <div key={`test-${i}`}>
                                    <blockquote
                                        dangerouslySetInnerHTML={{
                                            __html: v.node.content
                                        }}
                                    />
                                    <span>{v.node.name}</span>
                                </div>
                            )
                        })}
                    </TestimonialsWrapper>
                )
            }}
        </Query>
    )
}

const query = gql`
    query Testimonials($lang: String) {
        allReferences(lang: $lang) {
            edges {
                node {
                    name
                    content
                }
            }
        }
    }
`

export default withRouter(Testimonials)
