import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";
import Theme from "./Theme";
import { Page, StartPage } from "./Page";
import ScrollToTop from "./UI/Helpers";
import { Footer } from "./Footer";
import { Header } from "./Header";

const graph_uri =
  process.env.NODE_ENV === "development"
    ? "https://skandinaviskgradteknik.se/graphql"
    : "//skandinaviskgradteknik.se/graphql";

const client = new ApolloClient({
  uri: graph_uri,
});

const NoMatch = ({ location }) => (
  <div>
    <h3>Hoppsan...</h3>
  </div>
);

const Site = () => (
  <ScrollToTop>
    <Theme>
      <Header />
      <main>
        <Switch>
          <Route path="/:lang/:slug" component={Page} />
          <Route path="/:lang" component={StartPage} />
          <Route exact path="/" render={() => <Redirect to="/se" />} />
          <Route component={NoMatch} />
        </Switch>
      </main>
      <Footer />
    </Theme>
  </ScrollToTop>
);

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Router>
          <>
            <Route path="/:lang" component={Site} />
            <Route exact path="/" render={() => <Redirect to="/se" />} />
          </>
        </Router>
      </ApolloProvider>
    );
  }
}

export default App;
