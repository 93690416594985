import { NavLink } from "react-router-dom"
import React, { Component, useState } from "react"
import styled from "@emotion/styled"
import gql from "graphql-tag"
import { Query } from "react-apollo"
import { Below } from "../UI/Helpers"
import { withRouter } from "react-router"

const MenuButton = styled("li")`
	list-style: none;
	display: inline-block;
	font-size: 0;
	position: relative;
	margin-right: 1.5rem;
	${props => props.theme.bp.below.lg} {
		width: 100%;
		margin-right: 0;
		text-align: center;
	}
	a,
	span {
		font-size: 0.85rem;
		display: inline-block;
		padding: 1.5rem 0rem 3rem;
		text-align: center;
		text-decoration: none;
		color: #fff;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 3px;
		position: relative;
		width: 100%;
		cursor: pointer;
		${props => props.theme.bp.below.lg} {
			padding: 0.5rem 0;
			font-size: 1rem;
			line-height: 2.5rem;
			max-width: calc(100% - 2rem);
			display: block;
			margin: 0 1.5rem;
			text-align: center;
			text-transform: none;
			color: #000;
			text-transform: uppercase;
		}

		i {
			margin-left: 0.5rem;
			font-size: 0.6em;
		}
		&.open i {
			transform: rotate(180deg);
		}
		&.active {
			color: #4d9cce !important;
		}
		.page &,
		.is-scrolled & {
			color: #000;
		}
	}
	ul.subMenu {
		position: absolute;
		padding: 0;
		background: white;
		z-index: 2;
		top: 100%;
		left: 0;
		display: flex;
		flex-direction: column;
		${props => props.theme.bp.below.lg} {
			background: transparent;
			position: static;
		}
		a {
			color: ${props => props.theme.colors.primary};
			padding: 2rem;
			text-align: left;
			max-width: 9999px;
			white-space: nowrap;
			${props => props.theme.bp.below.lg} {
				padding: 0.5rem 0;
				background: none;
				font-weight: 200;
				color: white !important;
			}
		}
	}
`

const MenuWrapper = styled("ul")`
	flex-wrap: wrap;
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.2rem 1.8rem 0 0;
	margin: 0;
	width: 80vw;
	display: flex;
	justify-content: flex-end;

	${props => props.theme.bp.below.lg} {
		background: #fff !important;
		flex-wrap: wrap;
		padding: 0 0.5rem;
		transform: translateX(${props => (props.open ? "0" : "100%")});
		width: 100vw;
		padding: 2rem 1rem;
		position: fixed;
		top: 70px;
		right: 0;
		left: 0;
		align-content: center;
		justify-items: flex-start;
		z-index: 9;
	}
	transition: 0.2s;
`

const MenuToggle = styled("button")`
	position: absolute;
	top: -70px;
	font-size: 1.7rem;
	width: 3.5rem;
	height: 70px;
	padding: 0.5rem;
	color: black;
	right: ${props => (props.open ? "0" : "100%")};
	border: none;
	outline: none;
	background: none;
`
/*
const SearchToggle = styled(MenuButton)`
	width: 87px;
	text-align: center;

	span {
		i {
			margin-left: 0;
		}
	}
	&.isActive {
		background: white;
		span {
			color: ${props => props.theme.colors.primary};
			.handel &,
			.köpcenter & {
				color: ${props =>
					props.theme.transColor(props.theme.colors.retail, 0.7)};
			}
			.fastigheter & {
				color: ${props =>
					props.theme.transColor(props.theme.colors.properties, 0.7)};
			}
			.investeringar & {
				color: ${props =>
					props.theme.transColor(props.theme.colors.investments, 0.7)};
			}
		}
	}
`
*/

const LangSelector = styled("ul")`
	display: block;
	width: 100%;
	list-style-type: none;
	margin-right: 1.8rem;
	text-align: right;
	padding-top: 1rem;

	li {
		display: inline-block;
		font-weight: 400;
		margin-left: 1rem;
		font-size: 0.75rem;
		color: #fff;
		letter-spacing: 2px;
		line-height: 2em;
		position: relative;
		${props => props.theme.bp.below.md} {
			color: #000;
			margin-left: 0;
		}
		a {
			color: white;
			&.active {
				&:after {
					margin-bottom: -2.5px;
					border-bottom: 5px solid #4197cc;
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
				}
			}
			.page &,
			.is-scrolled & {
				color: #000;
			}
		}
	}
	${props => props.theme.bp.below.sm} {
		order: 99;
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		align-items: center;
		li {
			margin-bottom: 0.3rem;
		}
		a {
			color: #000 !important;
		}
	}
`

const MenuButtonWrapper = props => {
	const [open, setOpen] = useState(false)
	const className = open ? "open" : "closed"
	return (
		<MenuButton>
			<NavLink
				exact
				className={className}
				key={props.slug}
				to={"/" + props.lang + props.url}
				onClick={e => {
					if (props.children.length > 0) {
						e.preventDefault()
						setOpen(!open)
					} else {
						props.toggleMenu()
					}
				}}
			>
				{props.title}
				{props.children.length > 0 && <i className="fas fa-chevron-down" />}
			</NavLink>
			{open > 0 && (
				<SubMenu
					toggleMenu={props.toggleMenu}
					setOpen={setOpen}
					children={props.children}
				/>
			)}
		</MenuButton>
	)
}

const MenuContent = props => {
	const menu = props.menu
	console.log(menu)
	return (
		<MenuWrapper {...props}>
			<Below size="lg">
				<MenuToggle open={props.open} onClick={props.toggleMenu}>
					{props.open ? (
						<i className="fas fa-times" />
					) : (
						<i className="fas fa-bars" />
					)}
				</MenuToggle>
			</Below>
			<LangSelector>
				<li>
					<NavLink to="/se">SV</NavLink>
				</li>
				<li>
					<NavLink to="/en">ENG</NavLink>
				</li>
			</LangSelector>
			{menu.items.map((m, i) => (
				<MenuButtonWrapper {...props} {...m} key={i} />
			))}
		</MenuWrapper>
	)
}

const SubMenu = props => {
	return (
		<ul className="subMenu">
			{props.children.map((m, i) => (
				<MenuButton key={i} onClick={() => props.setOpen(false)}>
					<NavLink
						onClick={e => {
							props.toggleMenu()
						}}
						exact
						key={m.slug + "-" + i}
						to={m.url}
					>
						{m.title}
					</NavLink>
				</MenuButton>
			))}
		</ul>
	)
}

const MenuLoading = () => (
	<MenuWrapper>
		<MenuButton>
			<span>Laddar meny...</span>
		</MenuButton>
	</MenuWrapper>
)

class Menu extends Component {
	render() {
		const lang = this.props.match.params.lang || "se"
		return (
			<Query query={query} variables={{ lang }}>
				{({ loading, err, data }) => {
					if (loading) return <MenuLoading />
					return <MenuContent lang={lang} {...data} {...this.props} />
				}}
			</Query>
		)
	}
}
const query = gql`
	query Menu($lang: String) {
		menu(position: "header", lang: $lang) {
			items {
				title
				url
				children {
					title
					url
				}
			}
		}
	}
`
export default withRouter(Menu)
