import styled from "@emotion/styled"
import React from "react"
import { HeaderContent } from "../Page/PageHeader"
import default_bg from "../img/default-bg.jpg"

export const PlaceholderWrapper = styled("div")`
    height: 100vh;
    position: relative;
    background: url(${default_bg});
    ${props => props.theme.bp.below.md} {
        display: flex;
        min-height: 100vh;
        height: auto;
        align-items: flex-end;
    }
`

export const BumperHeader = styled("div")`
    height: 180px;
    background: #f7f7f7;
    position: relative;
    ${props => props.theme.bp.below.md} {
        height: 120px;
    }
`

export const PlaceholderHeader = props => {
    return (
        <PlaceholderWrapper>
            <HeaderContent>
                <h1>{props.headTitle ? props.headTitle : props.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: props.excerpt }} />
            </HeaderContent>
        </PlaceholderWrapper>
    )
}
