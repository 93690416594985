import React, { Fragment, useState, useEffect } from "react"
import gql from "graphql-tag"
import styled from "@emotion/styled"
import { Mutation } from "react-apollo"
import { MaxWidth, Flex } from "../UI/Containers"
import { SubmitButton } from "../UI/Button"
import { Input, TextArea } from "../UI/Input"
import Map from "pigeon-maps"
import Marker from "pigeon-marker"

const FormContainer = styled("section")`
	padding: ${props => props.theme.spacing.default};
	text-align: center;
`
const FormFlex = styled(Flex)`
	align-items: stretch;
	flex-wrap: wrap;
	width: 800px;
	max-width: 100%;
	margin: 0 auto;
	justify-content: flex-end;
	${props => props.theme.bp.below.sm} {
		flex-wrap: wrap;
	}
	& > div {
		width: 100%;
		padding: 0;
		flex: 1 1 auto;
		display: flex;
		${props => props.theme.bp.below.md} {
			flex-wrap: wrap;
			margin: 0 auto;
			input {
				margin: 0.2rem 0;
			}
		}
		${props => props.theme.bp.below.sm} {
			padding: 0 !important;
		}
		${props => props.theme.bp.below.sm} {
			width: 100%;
			flex: unset;
		}

		padding: 0.2rem 0rem;

		* {
			margin: 0 0.2rem;
			${props => props.theme.bp.below.sm} {
				margin: 0;
				margin-bottom: 1rem;
			}
		}
		textarea {
		}
	}
`

const PageContent = styled("div")`
	width: 560px;
	margin: 0 auto;
	max-width: 100%;
`

const Contact = props => {
	const page = props.page
	const [form, setForm] = useState({})
	const [sent, setSent] = useState(false)

	const updateData = e => {
		let formData = form
		formData[e.target.name] = e.target.value
		setForm(formData)
	}

	useEffect(() => {
		const ga = window.ga

		if (sent && ga) {
			ga("send", "event", "form", "sent", "Contact form")
		}
	}, [sent])

	return (
		<Fragment>
			<Map
				center={[57.7483915, 12.9264156]}
				zoom={15}
				width={window.innerWidth}
				height={
					window.innerWidth > 600
						? window.innerWidth / 3
						: window.innerWidth * 1.2
				}
			>
				<Marker
					anchor={[57.7483915, 12.9264156]}
					payload={1}
					onClick={({ event, anchor, payload }) => {
						console.log(anchor, payload)
					}}
				/>
			</Map>
			<FormContainer>
				<MaxWidth>
					<h1>{page.title}</h1>
					<PageContent dangerouslySetInnerHTML={{ __html: page.content }} />
					{sent ? (
						<p className="mail-success">Ditt mail är skickat!</p>
					) : (
						<form onChange={updateData}>
							<FormFlex>
								<div>
									<Input name="name" placeholder="Ditt namn" required />
									<Input
										name="phone"
										placeholder="Ditt telefonnummer"
										required
									/>
								</div>

								<div>
									<Input
										name="email"
										type="email"
										placeholder="Din mailadress"
										required
									/>
									<Input name="subject" placeholder="Ämnes" required />
								</div>

								<div>
									<TextArea
										name="message"
										placeholder="Ditt meddelande"
										required
									/>
								</div>
							</FormFlex>
							<Mutation
								mutation={FORM_MUTATION}
								variables={{ emailData: form }}
							>
								{(postMutation, { loading, error, data }) => (
									<FormFlex>
										{error && (
											<p>Något gick fel, har du fyllt i samtliga fält?</p>
										)}
										<SubmitButton
											onClick={e => {
												e.preventDefault()
												postMutation()
											}}
										>
											{loading ? "Skickar..." : "Skicka"}

											{data && setSent(data.sendEmail.ok)}
										</SubmitButton>
									</FormFlex>
								)}
							</Mutation>
						</form>
					)}
				</MaxWidth>
			</FormContainer>
		</Fragment>
	)
}

const FORM_MUTATION = gql`
	mutation SendMail($emailData: EmailInput!) {
		sendEmail(emailData: $emailData) {
			ok
		}
	}
`

export default Contact
