import styled from "@emotion/styled"
import { Link } from "react-router-dom"

const Button = styled(Link)`
	color: #fff;
	border: 1px solid #fff;
	display: inline-block;
	margin: 2em auto;
	text-transform: uppercase;
	padding: 12px 30px;
	border-radius: 100px;
	font-weight: 400;
	letter-spacing: 3px;
	font-size: 15px;
`

export const SubmitButton = styled("button")`
	background: #ffb35b;
	color: #fff;
	border: 1px solid #fff;
	display: inline-block;
	margin: 0;
	text-transform: uppercase;
	padding: 14px 35px;
	border-radius: 0;
	font-weight: 400;
	letter-spacing: 3px;
	font-size: 15px;
	${props => props.theme.bp.below.sm} {
		width: 100%;
		margin: 10px auto;
	}
`

export default Button
