import styled from "@emotion/styled"

const Input = styled("input")`
	border: 1px solid #000;
	padding: 15px;
	font-size: 16px;
	display: block;
	width: 100%;
`
const TextArea = styled("textarea")`
	border: 1px solid #000;
	padding: 15px;
	font-size: 18px;
	display: block;
	width: 100%;
	height: 150px;
	resize: none;
`
export { Input, TextArea }
