import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-xhr-backend"
import resources from "./translations.json"

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        lng: window.location.pathname.split("/")[1],
        fallbackLng: "en",
        debug: true,

        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    })

export default i18n
