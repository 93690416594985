import React, { Component } from "react"
import styled from "@emotion/styled"
import { Link } from "react-router-dom"
import default_bg from "../img/default-bg.jpg"

const ImageWrapper = styled("header")`
    position: ${props => (props.page ? "relative" : "fixed")};
    width: 100vw;
    height: ${props => (props.page ? "77vh" : "100vh")};
    background-image: url(${props =>
        props.image
            ? "//skandinaviskgradteknik.se" + props.image
            : default_bg});
    background-size: cover;
    overflow: hidden;
    background-position: bottom left;
    z-index: 1;
    top: 0;
`

export const HeaderContent = styled("div")`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: ${props => (props.page ? "auto" : "0")};
    left: ${props => (props.page ? "0" : "auto")};
    padding: 0 2rem;
    top: 0;
    width: 39vw;
    height: 100%;
    text-align: center;
    color: #fff;
    align-items: flex-start;
    ${props => props.theme.bp.below.md} {
        width: 100%;
        padding-top: 70px;
    }
    &:after {
        display: block;
        content: "";
        position: absolute;
        height: 200%;
        width: 100%;
        border-left: 2px solid #c6c6c6;
        transform: rotate(${props => (props.page ? "-30deg" : "30deg")});
        z-index: 1;
        right: ${props => (props.page ? "-62vw" : "auto")};
        left: ${props => (props.page ? "auto" : "-35vw")};
        ${props => props.theme.bp.below.md} {
            right: auto;
            left: 110vw;
            transform: rotate(30deg);
        }
    }
    &::before {
        clip-path: ${props =>
            props.page
                ? "polygon(0 0, 100% 0%, 50% 100%, 0 100%)"
                : "polygon(0 0, 100% 0%, 100% 100%, 50% 100%)"};
        display: block;
        content: "";
        position: absolute;
        width: 73vw;
        height: 100%;
        top: 0;
        background: rgba(0, 72, 119, 0.6);
        z-index: 1;
        right: ${props => (props.page ? "auto" : "0")};
        left: ${props => (props.page ? "0" : "auto")};
        ${props => props.theme.bp.below.md} {
            width: 100vw;
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 85% 100%);
        }
    }

    div {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    }
    h1 {
        z-index: 2;
        font-size: ${props => (props.page ? "5rem" : "62px")};
        line-height: 0.95em;
        width: 100%;
        margin-bottom: ${props => (props.page ? "0" : ".4em")};
        padding-top: ${props => (props.page ? "120px" : "0")};
        ${props => props.theme.bp.below.md} {
            font-size: 2.2rem;
            margin-bottom: 0.5rem;
            padding-top: 0;
        }
    }
    a {
        color: #fff;
        z-index: 2;
        ${props => props.theme.bp.below.md} {
            margin-top: 1rem;
        }
    }
    p,
    & > div {
        font-size: 16px;
        line-height: 1.5em;
        max-width: 100%;
        margin: 0 auto;
        z-index: 2;
        ${props => props.theme.bp.below.md} {
            display: flex;
            flex-direction: column;
            justify-content: center;
            justify-items: center;
            width: 80%;
            margin: auto !important;
        }
    }
`

const Button = styled(Link)`
    color: #fff;
    border: 1px solid #fff;
    display: inline-block;
    margin: 3em auto 0;
    text-transform: uppercase;
    padding: 15px 60px;
    font-weight: 400;
    letter-spacing: 2px;
    ${props => props.theme.bp.below.md} {
        margin-bottom: 0;
        padding: 10px 60px;
    }
`

const ContentContainer = styled("div")`
    margin-left: ${props => (props.page ? "auto" : "-10%")} !important;
    margin-right: ${props => (props.page ? "-10%" : "auto")} !important;
    width: 540px !important;
`

class PageHeader extends Component {
    render() {
        const { pageData, page, template } = this.props
        return (
            <ImageWrapper page={page} image={pageData.image.url}>
                {template !== "CONTACT" && (
                    <HeaderContent page={page}>
                        <ContentContainer page={page}>
                            {pageData.headTitle ? (
                                <h1
                                    dangerouslySetInnerHTML={{
                                        __html: pageData.headTitle
                                    }}
                                />
                            ) : (
                                <h1>{pageData.title}</h1>
                            )}

                            <div
                                dangerouslySetInnerHTML={{
                                    __html: pageData.excerpt
                                }}
                            />
                            {pageData.headLink && (
                                <Button to={pageData.headLink}>
                                    {pageData.headLinkText}
                                </Button>
                            )}
                        </ContentContainer>
                    </HeaderContent>
                )}
            </ImageWrapper>
        )
    }
}

export default PageHeader
