import React, { Component } from "react"

function Unix_timestamp(t) {
    var dt = new Date(t * 1000),
        locale = "sv-se",
        date = dt.getDate(),
        year = dt.getFullYear(),
        month = dt.toLocaleString(locale, {
            month: "long"
        })
    return date + " " + month + " " + year
}

class DateDisplay extends Component {
    render() {
        const { timestamp } = this.props
        return (
            <React.Fragment>
                {timestamp && <time>{Unix_timestamp(timestamp)}</time>}
            </React.Fragment>
        )
    }
}

export default DateDisplay
