import React, { Component } from "react"
import styled from "@emotion/styled"

const LoadingMessage = styled("div")`
	padding: 1rem;
`

class LadingPage extends Component {
	render() {
		return <LoadingMessage>Laddar sida...</LoadingMessage>
	}
}

export default LadingPage
