import styled from "@emotion/styled"

export const MaxWidth = styled("div")`
    width: ${props => props.theme.sizes.maxWidth};
    max-width: 100%;
    margin: 0 auto;
`

export const FlexMaxWidth = styled(MaxWidth)`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    ${props => props.theme.bp.below.md} {
        flex-direction: column;
    }
`

export const Flex = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const FlexWrap = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`
