import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { LoadingPage } from "../UI";
import PageHeader from "./PageHeader";
import { Link } from "react-router-dom";
import { Route } from "react-router-dom";
import Testimonials from "./Components/Testimonials";

const StartPageSection = styled("section")`
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  margin-top: 100vh;
  position: relative;
  z-index: 4;
  > div {
    width: 50%;
    padding: 20px;
    ${(props) => props.theme.bp.below.md} {
      width: 100%;
    }
  }

  p {
    line-height: 1.5em;
  }
`;

export const StyledBox = styled("section")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  a {
    color: #4d9cce;
  }
  h2 {
    letter-spacing: ${(props) => (props.page ? "3px" : "0")};
    font-size: ${(props) => (props.page ? ".9rem" : "2.6rem")};
    margin-bottom: ${(props) => (props.page ? "0" : "1em")};
    line-height: 1;
    font-family: ${(props) =>
      props.page ? props.theme.fonts.body : props.theme.fonts.heading};
    ${(props) => props.theme.bp.below.md} {
      line-height: 1.1em;
      margin-bottom: 0.5em;
      font-size: 2rem;
    }
  }
  div {
    width: 50%;
    height: auto;
    ${(props) => props.theme.bp.below.md} {
      width: 100%;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px 3rem;
    font-size: 16px;
    line-height: 1.6;
    ${(props) => props.theme.bp.below.sm} {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    div {
      width: 100%;
      max-width: 560px;
      margin: 0 auto;
    }
  }
  &:nth-child(1) {
    .content {
      padding-top: 10rem;
      ${(props) => props.theme.bp.below.md} {
        padding-top: 3rem;
      }
    }
  }
  &:last-of-type {
    .content {
      padding-bottom: 10rem;
      ${(props) => props.theme.bp.below.md} {
        padding-bottom: 3rem;
      }
    }
  }
  .image {
    position: relative;
    .video-wrapper {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% * 0.9);
      height: 0;
      padding-bottom: calc(56.25% * 0.9);
      iframe {
        width: 100%;
        max-width: 100%;
        height: 100%;
      }
    }
    background: ${(props) =>
      props.img
        ? "url(//skandinaviskgradteknik.se" + props.img.url + ")"
        : "transparent"};
    background-size: cover;
    background-position: center center;
    img {
      width: 100%;
      height: 100%;
      display: none;
    }
    ${(props) => props.theme.bp.below.sm} {
      height: 100vw;
    }
  }
  blockquote {
    font-family: ${(props) => props.theme.fonts.headings};
    font-size: ${(props) => (props.page ? "1.6rem" : "1.8rem")};
    line-height: 1.2em;
    text-align: center;
    padding: 1rem;
    span {
      color: #ffb35a;
    }
    &:before,
    &:after {
      content: "”";
    }
  }
`;

const StyledCTA = styled("div")`
  display: block;
  background: #4298cc;
  color: #fff;
  width: 100% !important;
  text-align: center;
  padding: 60px 40px !important;
  h3 {
    font-size: 2.3rem;
    margin-bottom: 0.2em;
    ${(props) => props.theme.bp.below.md} {
      font-size: 1.5rem;
    }
  }
  p {
    margin-bottom: 2em;
  }
  a {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    border: 1px solid #fff;
    padding: 1em 3em;
    letter-spacing: 1px;
    display: inline-block;
    font-size: 0.9rem;
  }
`;

const Category = styled(Link)`
  width: 33.333%;
  position: relative;
  ${(props) => props.theme.bp.below.md} {
    width: 100%;
    h4 {
      width: 100%;
      line-height: 1.5em;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
  h4 {
    width: 50%;
    font-family: inherit;
    line-height: 3.5em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 15px;
    border: 1px solid #fff;
    ${(props) => props.theme.bp.below.md} {
      width: 80%;
      line-height: 2.5em;
    }
  }
`;

export const CTA = () => {
  return (
    <>
      <Route
        path="/se"
        render={() => {
          return (
            <StyledCTA>
              <h3>Vad kan vi göra för dig?</h3>
              <p>
                Behöver du hjälp eller rådgivning? Eller vill du jobba hos oss?
                Tveka inte att höra av dig!
              </p>
              <Link to="/se/kontakt">Kontakta oss</Link>
            </StyledCTA>
          );
        }}
      />
      <Route
        path="/en"
        render={() => {
          return (
            <StyledCTA>
              <h3>How can we help you?</h3>
              <p>
                Looking for an answer to a question? Wish to book an
                appointment? Please don’t hesitate to get in touch!
              </p>
              <Link to="/en/contact">Contact</Link>
            </StyledCTA>
          );
        }}
      />
    </>
  );
};

const CategoryBox = (props) => (
  <Category to={props.link}>
    {console.log(props)}
    <img alt="" src={"//skandinaviskgradteknik.se/" + props.image.url} />
    <h4>{props.linkText}</h4>
  </Category>
);

const PageContent = (props) => {
  const page = props.startpage;

  return (
    <div>
      <Helmet>
        {}
        <meta charSet="utf-8" />
        <title>{page.title}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      {page.image.url ? <PageHeader pageData={page} isStartPage /> : null}
      <StartPageSection>
        {page.boxSet.map((v, i) => {
          console.log(v.template);
          if (v.template === "PUSHBOX") {
            return <CTA key={i} />;
          }
          if (v.template === "REFERENCES") {
            return <Testimonials key={i} />;
          }
          if (v.template === "LINKBOX") {
            return <CategoryBox {...v} key={i} />;
          }
          return (
            <StyledBox img={v.image.url ? v.image : null} key={`box-${i}`}>
              <div className="content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: v.content,
                  }}
                />
              </div>
              {v.image.url && (
                <div className="image">
                  {i === 0 && (
                    <div class="video-wrapper">
                      <iframe
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                        frameborder="0"
                        height="315"
                        src="https://www.youtube.com/embed/L2GTx-pYKq8?modestbranding=1&amp;rel=0"
                        title="YouTube video player"
                        width="560"
                      />
                    </div>
                  )}
                  <img
                    src={"//skandinaviskgradteknik.se" + v.image.url}
                    alt=""
                  />
                </div>
              )}
            </StyledBox>
          );
        })}
      </StartPageSection>
    </div>
  );
};

class StartPage extends Component {
  render() {
    const lang = this.props.match.params.lang || "se";
    return (
      <Query query={query} variables={{ lang }}>
        {({ loading, err, data }) => {
          if (loading) return <LoadingPage />;
          return <PageContent {...data} />;
        }}
      </Query>
    );
  }
}
const query = gql`
  query startPage($lang: String) {
    startpage(lang: $lang) {
      headTitle
      excerpt
      headLink
      headLinkText
      title
      content
      image {
        url
      }
      boxSet {
        content
        template
        link
        linkText
        image(version: "large") {
          width
          height
          url
        }
      }
    }
  }
`;
export default StartPage;
